
import {
  atlasfileMapActions,
  atlasfileMapState
} from "@/store/modules/atlasfile";
import Vue from "vue";

interface IData {
  loading: boolean;
}

export default Vue.extend({
  name: "search-select-documents",
  props: {
    selected: {
      type: [Number, String, Array, Boolean],
      default: ""
    },
    label: {
      type: String,
      default: "",
      required: false
    }
  },
  mounted() {
    if (Array.isArray(this.selected) && this.selected.length > 0) {
      this.makeOptionsForSelectedDocuments(this.selected as any);
    }
  },
  data(): IData {
    return {
      loading: false
    };
  },
  methods: {
    ...atlasfileMapActions(["getAtlasfiles"]),
    async remoteMethod(searchTerm: string) {
      if (searchTerm.trim().length === 0) return;
      try {
        this.loading = true;
        await this.getAtlasfiles({
          query: { __query: searchTerm }
        });
      } catch (error) {
        this.$appNotifyError("Error fetching documents");
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = true;
      }
    },
    async makeOptionsForSelectedDocuments(selected: string[]) {
      await this.getAtlasfiles({ query: { _id__in: selected } });
    }
  },
  computed: {
    ...atlasfileMapState(["atlasfiles"]),
    options(): any[] {
      const options = [];
      for (const document of this.atlasfiles) {
        options.push({
          label: document.name,
          value: document._id
        });
      }
      return options;
    }
  }
});
